import { History } from "history"
import { decodeJwtToken } from "@tm/utils"
import { AuthTokenPayload } from "@tm/models"

export type LoginMatch = {
    catalog: string,
    redirect?: string,
    token?: string
    lang?: string
    traderId?: string | undefined
}

export function matchLoginUrl(history: History): LoginMatch | undefined {
    const { pathname, search } = history.location
    let match = /^\/login(?:-error)?\/?(.+?)?\/?$/.exec(pathname)

    if (!match) return

    let catalog = match[1]

    let redirect: string | undefined
    let token: string | undefined
    let lang: string | undefined
    let traderId: string | undefined

    if (search) {
        const params = new URLSearchParams(search)
        redirect = params.get("redirect") || undefined
        token = params.get("token") || undefined
        lang = params.get("lang") || undefined
        traderId = params.get("trader") || undefined
    }

    if (token) {
        // Try to get the catalog from the supplied token
        try {
            const { catalogname } = decodeJwtToken<AuthTokenPayload>(token)

            if (catalogname) {
                catalog = catalogname
            }
        } catch { }
    }

    return { catalog, redirect, token, lang, traderId }
}
