import { init } from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { AppConfig } from "../data/loadConfiguration"

/**
 * Initialisiere das Sentry Script
 * @memberof Portal
 * @param sentry Sentry Config
 */
export function initSentry(config: AppConfig) {
    const { dsn, env: environment } = config.sentry ?? {}

    // Only active if
    // - setting is enabled in config
    // - local mocking is not activated (when debugging locally) and
    // - is running on development system
    if (!config.sentry || process.env.ENABLE_MOCK || environment !== "dev") {
        return
    }

    init({
        dsn,
        integrations: [new BrowserTracing()],
        environment,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.7,
        attachStacktrace: true,
        maxBreadcrumbs: 50,
        initialScope: {
            tags: {
                "catalog": config.title || "not defined",
            },
        },
    })
}
