import { ajaxAuth } from "@tm/utils"
import { CostEstimation, CostEstimationVoucherCustomer, CostEstimationVoucherVehicle } from "./model"

export function create(body: CreateRequest) {
    // TODO configurable cost-estimation:  Rename to Create when the original Create is no longer used
    const url = "/data/TM.Next.Vouchers/Vouchers/CreateV2"
    return ajaxAuth<CreateResponse>({ url, body, method: "POST" })
}

export type CreateRequest = {
    workTaskId: string
    workTaskNumber?: string
    customer?: CostEstimationVoucherCustomer
    vehicle?: CostEstimationVoucherVehicle
    costEstimation: CostEstimation
    printOptions: PrintOptions
}

export type PrintOptions = {
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    showManufacturer: boolean
    showVehicleImage: boolean
}

export type CreateResponse = {
    id: string
}
