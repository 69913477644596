import { EmbeddedElement } from "@tm/models"
import { AppConfig } from "../data/loadConfiguration"

function addElement(prop: EmbeddedElement, targetName: "body" | "head") {
    const element = document.createElement(prop?.element ?? "script")

    if (prop.src && element instanceof HTMLScriptElement) {
        element.src = prop.src
        element.async = prop.async ?? false
        element.defer = prop.defer ?? false
    }

    if (prop.attribute?.name && prop.attribute?.value) {
        element.setAttribute(prop.attribute.name, prop.attribute.value)
    }

    if (prop.innerHTML) {
        element.innerHTML = prop.innerHTML
    }

    const target = document.getElementsByTagName(targetName)[0]
    target.appendChild(element)
}

export function appendExternalScripts(config: AppConfig) {
    const { params: { externalScripts } } = config

    if (!externalScripts || typeof externalScripts !== "object") {
        return
    }

    Object.values(externalScripts).forEach(script => {
        Object.entries(script!).forEach(([key, elements]) => {
            const lowerCaseKey = key.toLocaleLowerCase()

            if (lowerCaseKey === "body" || lowerCaseKey === "head") {
                elements.forEach((element) => {
                    if (!!element) {
                        addElement(element, lowerCaseKey)
                    }
                })
            }
        })
    })
}
