import { LoginMatch } from "./matchLoginUrl"
import { setStoredLogin } from "@tm/utils"

/***
 * @description aka loginH(idden)
 */
export function trySingleSignOnAndRedirect(loginMatch: LoginMatch, localeId: string, callback: () => void|Promise<any>) {
    if (loginMatch.token) {
        setStoredLogin({
            username: "",
            token: loginMatch.token,
            schema: "Bearer",
            expireDate: new Date(Date.now() + (43199 * 1000)),
            locale: localeId,
        })

        const waiting = callback()
        if (waiting) {
            waiting.finally(redirect.bind(null, loginMatch))
            return true
        }
        else {
            redirect(loginMatch)
            return true
        }
    }

    return false
}

function redirect(loginMatch: LoginMatch) {
    const redirectUrl = loginMatch.redirect

    if (redirectUrl) {
        // if there's a redirect url, don't show any external homepage
        if (redirectUrl) {
            sessionStorage.setItem("HOMEPAGE_SHOWN", "1")
        }

        location.replace(`/${redirectUrl}`)
        return true
    }

    location.replace("/")
    return true
}
