import { createBrowserHistory } from "history"
import { ReactNode, useEffect, useMemo } from "react"
import { createPortal, render } from "react-dom"
import { QueryClientProvider } from "react-query"
import { Route, Router, Switch } from "react-router"
import { RecoilRoot } from "recoil"

import { AlertNotification, SizeProvider, ThemeProvider, WorkTaskProvider, createUserProvider, loadStyleTheme, useModuleNavigation } from "@tm/context-distribution"
import { appendControlsConfig } from "@tm/controls"
import { ModuleTab, SystemType, channel } from "@tm/models"
import Morpheus, { IContext } from "@tm/morpheus"
import { TmaEventTracking, hasValidStoredLogin, initMainHistory } from "@tm/utils"

import BrowserCompabilityCheck from "../components/BrowserCompabilityCheck"
import { CheckUserCredentialsSet } from "../components/CheckUserCredentialsSet"
import Modal from "../components/Modal"
import { UpdateChecker } from "../components/UpdateChecker"
import { AppMessageCenter } from "../components/app-message-center"
import ErrorComponent from "../components/error"
import { getCatalogTexts, getCatalogTextsLogin } from "../data/translations"
import { initMock } from "../mocks"
import * as KeyValueStoreRepo from "../repositories/keyValueStore"
import * as ViewStateRepo from "../repositories/viewstate"
import { createQueryClient } from "./data/createQueryClient"
import { initCachingWhitelist } from "./data/initCachingWhitelist"
import { loadAssets } from "./data/loadAssets"
import { loadBaseConfig } from "./data/loadBaseConfig"
import { AppConfig, ConfigType, loadConfiguration } from "./data/loadConfiguration"
import { loadSprites } from "./data/loadSprites"
import { loadStyles } from "./data/loadStyles"
import { resetLoadProgress } from "./data/loadingProgress"
import { registerAjaxErrorHandler } from "./data/registerAjaxErrorHandler"
import { appendExternalScripts } from "./document/appendExternalScripts"
import { clearSavedCatalog, getCatalog } from "./document/currentCatalogStorage"
import { initPortalLocations } from "./document/initPortalLocations"
import { redirectToExternalSystem } from "./document/redirectToExternalSystem"
import { setDocumentMetadata } from "./document/setDocumentMetadata"
import { setupProgressiveWebApp } from "./document/setupProgressiveWebApp"
import { getLanguageId } from "./localization/getLanguageId"
import { getLocalizationProvider } from "./localization/getLocalizationProvider"
import { setNextShellLanguage } from "./localization/setNextShellLanguage"
import { initLogging } from "./logging/initLogging"
import { initSentry } from "./logging/initSentry"
import { setBrowserTabId } from "./logging/setBrowserTabId"
import { getLoginComponents } from "./login/getLoginComponents"
import { registerLogoutContainerAction } from "./login/handleLogout"
import { matchLoginUrl } from "./login/matchLoginUrl"
import { redirectToLogin } from "./login/redirectToLogin"
import { getStylesUrl } from "./theming/getStylesUrl"
import { getThemeUrl } from "./theming/getThemeUrl"
import { useHasConcept } from "./theming/useHasConcept"
import { SnackbarProvider } from "../components/Snackbar"
import { Drawer } from "../components/Drawer"


function MainContent(props: { morpheus: Morpheus }) {
    const { morpheus } = props
    const { showTab } = useModuleNavigation()

    // This has to be called with useMemo. useEffects is too late, when you reload the portal
    useMemo(() => morpheus.addBroadcastHandler("MODULE_OPENED", showTab), [showTab])

    return <>{morpheus.render()}</>
}

export async function initializePortal(instantiateMorpheus: (config: any) => Morpheus) {
    setBrowserTabId()

    initLogging()

    const history = createBrowserHistory()
    initMainHistory(history)
    initPortalLocations(history)

    const baseConfig = await loadBaseConfig()
    initCachingWhitelist()

    const loginMatch = matchLoginUrl(history)
    const catalog = getCatalog(loginMatch)
    const configType: ConfigType = loginMatch ? "LOGIN" : "APP"

    if (!loginMatch && !hasValidStoredLogin()) {
        // If user is not logged in, go to login page
        redirectToLogin(history, catalog)
        return
    }

    registerAjaxErrorHandler(history)
    initMock()

    const appConfig = await loadConfiguration(baseConfig, configType, catalog, location.hostname, loginMatch?.traderId)

    if (!appConfig) {
        clearSavedCatalog()

        if (!location.pathname.startsWith("/login")) {
            // TODO: check if "redirectToLogin(catalog)" could be used here
            location.replace("/login")
        }

        return
    }
    setupProgressiveWebApp(appConfig)

    if (!appConfig.disableRedirectToExternalSystem) {
        channel("GLOBAL").subscribeOnce("USER/CONTEXT_LOADED", redirectToExternalSystem.bind(null, history))
    }

    // disable tracking when it's disabled in the config
    if (appConfig.params.disableAnalytics) {
        TmaEventTracking.disableTracker()
    }

    // remember the authentication infos
    const { authId } = appConfig.login;
    (window as any).auth = authId ? { authId } : appConfig.authentication

    const hasConcept = useHasConcept(appConfig)

    const loginServiceUrl = appConfig.login.serviceUrl
    loadStyles(getStylesUrl(appConfig.styles, hasConcept))
    loadSprites(appConfig.icons)

    ViewStateRepo.initialize(appConfig.params.stateServiceUrl)
    KeyValueStoreRepo.initialize(appConfig.params.keyValueStoreServiceUrl)

    registerLogoutContainerAction(history, appConfig, catalog)

    const morpheus = instantiateMorpheus(appConfig)

    initSentry(appConfig)

    appendExternalScripts(appConfig)
    appendControlsConfig(appConfig)

    const languageId = getLanguageId(appConfig, loginMatch)
    setNextShellLanguage(languageId)

    // Load login and login error component
    const [LoginComponent, LoginErrorComponent] = loginMatch ? (getLoginComponents(appConfig, loginMatch, catalog, languageId, loginServiceUrl) ?? []) : []

    await loadStyleTheme(getThemeUrl(appConfig.styles, hasConcept))

    await loadAssets(morpheus)

    const LocalizationProvider = await getLocalizationProvider(
        languageId,
        appConfig,
        (configType === "LOGIN" ? getCatalogTextsLogin : getCatalogTexts).bind(undefined, appConfig.params.translationsServiceUrl, authId)
    )

    const UserProvider = appConfig.params.authorityServiceUrl ? await createUserProvider({
        authorityServiceUrl: appConfig.params.authorityServiceUrl,
        repairShopServiceUrl: appConfig.params.repairShopServiceUrl,
        externalAuthentication: appConfig.login.externalAuthentication ?? {},
        tokenHandler: appConfig.params.tokenHandler,
        userSettingsDefaults: appConfig.params.userSettingsDefaults
    }) : undefined

    setDocumentMetadata(appConfig, window.userContext)

    const modals = document.querySelector("#modals")

    morpheus.init(history)

    let modalElement: Modal | null
    let modalInitialOpened = false
    const modalView = morpheus.createView("1", action => {
        if (modalElement) {
            modalElement.toggle(action)
        }
        else {
            modalInitialOpened = action === "OPEN"
        }
    })

    resetLoadProgress()

    render((
        <ThemeProvider>
            <LocalizationProvider>
                <>
                    {appConfig.login.browserCompatibilityCheck && <BrowserCompabilityCheck {...appConfig.login.browserCompatibilityCheck} />}
                    <UpdateChecker />
                    <Router history={history}>
                        <Switch>
                            <Route path="/login" component={LoginComponent} />
                            <Route path="/login-error" component={LoginErrorComponent} />
                            <QueryClientProvider client={createQueryClient()}>
                                <RecoilRoot>
                                    <SnackbarProvider>
                                        {
                                            !!UserProvider && <UserProvider>
                                                <SizeProvider>
                                                    <AlertNotification erpServiceUrl={appConfig.params.erpServiceUrl} />
                                                    {wrapWithWorktaskProvider(
                                                        appConfig,
                                                        morpheus.context,
                                                        <>
                                                            <MainContent morpheus={morpheus} />
                                                            {
                                                                !!modals &&
                                                                createPortal((
                                                                    <Modal name="1" opened={modalInitialOpened} ref={el => modalElement = el}>
                                                                        {modalView}
                                                                    </Modal>
                                                                ), modals)
                                                            }
                                                            {!!modals && createPortal(<AppMessageCenter />, modals)}
                                                            <Drawer morpheus={morpheus} />
                                                        </>
                                                    )}
                                                    <CheckUserCredentialsSet />
                                                </SizeProvider>
                                            </UserProvider>
                                        }
                                    </SnackbarProvider>
                                </RecoilRoot>
                            </QueryClientProvider>
                        </Switch>
                    </Router>
                    <ErrorComponent />
                </>
            </LocalizationProvider>
        </ThemeProvider>
    ), document.querySelector("#app"))
}


function wrapWithWorktaskProvider(config: AppConfig, context: IContext, content: ReactNode) {
    const globalPages: string[] = []
    context.routes.forEach(({ path }) => {
        if (/(^\/\:|^\/$)/.test(path)) {
            return
        }
        const firstFragment = path.split("/")[1]
        if (!globalPages.includes(firstFragment)) {
            globalPages.push(firstFragment)
        }
    })
    if (!config.systemType || config.systemType == SystemType.Next) {
        return <WorkTaskProvider globalPages={globalPages}>{content}</WorkTaskProvider>
    }

    return content
}
